import { FC } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import { DataDescriptorEntity, Enterprise as App, EnterpriseAppState } from "@ctra/api";
import { Col, Row, Skeleton } from "@ctra/components";
import { classname } from "@ctra/utils";

import { Chart, ChartFilterContext, ChartAPIContext } from "@chart";
import { useInsight, InsightValidation, KPIInsight } from "@insights";

import baseStyles from "../InsightBody/InsightBody.module.less";

/**
 * Insight body for KPI insights
 * @constructor
 */
export const KPIInsightBody: FC = () => {
  const {
    insight: { farm },
    getMetadata,
    getTargetGroupName,
    meta: { isLoading }
  } = useInsight<KPIInsight>();

  const {
    dataDescriptor: { id }
  } = getMetadata();

  const descriptor = useSelector<EnterpriseAppState, DataDescriptorEntity>((state) =>
    App.entities.getDataDescriptor(state, { id })
  );

  /**
   * Get the group, which is either going to be herd group or pen
   */
  const [type, value] = _.split(getTargetGroupName(), " ");

  /**
   * if there is a pen name group, use that in filters
   */
  const penName = _.isEqual(type, "Pen") ? [value] : [];

  return (
    <Skeleton active loading={isLoading}>
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
      <Sentry.ErrorBoundary fallback={<>{null}</>}>
        <Row className={classname(baseStyles.Row, baseStyles.PaddedChart)}>
          <Col flex={1}>
            <ChartFilterContext.Provider
              series={_.size(penName) ? _.values(penName) : void 0}
              filters={_.size(penName) ? { penName } : void 0}
            >
              <ChartAPIContext.Provider dataDescriptorID={id} farmID={farm}>
                <Chart viewOptions={{ showToolbar: true, zoomEnabled: false }} />
              </ChartAPIContext.Provider>
            </ChartFilterContext.Provider>
          </Col>
        </Row>
      </Sentry.ErrorBoundary>
    </Skeleton>
  );
};
