import { FC } from "react";
import * as _ from "lodash";
import { Link, withRouter, RouteComponentProps, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Menu,
  Badge,
  AppstoreOutlined,
  CompassOutlined,
  ClockCircleOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  BulbOutlined,
  MessageOutlined,
  InfoCircleOutlined,
  ExperimentOutlined,
  Icon,
  Copilot,
  UserOutlined
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { classname } from "@ctra/utils";
import { Session } from "@ctra/api";

import { useIntercom, useUserPreferences } from "@base";
import { useFarmList } from "@farms";
import { Routes } from "@routes";
import { useCurrentUser } from "@auth";

import { GACategories } from "../../analytics";
import { intercomLauncherClassName } from "../Intercom";

import styles from "./Navigation.module.less";

type NavigationProps = RouteComponentProps & {
  /**
   * callback for toggling the collapsed state
   * @param {boolean} collapsed
   */
  onToggle?: (collapsed: boolean) => void;
  /**
   * collapsed state of the navigation controlled by sider
   */
  collapsed?: boolean;
};

/**
 * Main sidebar navigation
 * @type {React.ComponentClass<Omit<NavigationProps, keyof RouteComponentProps<any>> & WithRouterProps<React.FC<NavigationProps>>> & WithRouterStatics<React.FC<NavigationProps>>}
 */
export const Navigation = withRouter<NavigationProps, FC<NavigationProps>>(
  ({ location: { pathname }, collapsed, onToggle = () => void 0 }) => {
    const { t } = useTranslation();
    const { isChatOpen, unreadCount } = useIntercom();
    const dispatch = useDispatch();
    const { farmList } = useFarmList();

    const {
      preferences: {
        sandbox: { isEnabled: sandboxEnabled }
      }
    } = useUserPreferences();

    const {
      meta: { isAdmin }
    } = useCurrentUser();

    const dataIntegrated = _.size(farmList) > 0;

    /**
     * Route for settings item, used for deep navigation highlighting
     * e.g /settings/insights
     */
    const settings = useRouteMatch(`${Routes.app.settings.index}*`);
    const myNetwork = useRouteMatch(`${Routes.app.network.index}*`);
    const iofc = useRouteMatch(`${Routes.app.iofc.index}*`);
    const farmSummaries = useRouteMatch(`${Routes.app.farmSummaries.index}*`);
    const timeline = useRouteMatch(`${Routes.app.timeline.index}*`);
    const analytics = useRouteMatch(`${Routes.app.analytics.index}*`);

    return (
      <>
        <Menu
          mode="inline"
          selectedKeys={[pathname]}
          className={classname(styles.Wrapper)}
          selectable={false}
          triggerSubMenuAction="click"
          style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}
          inlineIndent={14}
        >
          <Menu.Item icon={<AppstoreOutlined />} key={Routes.app.overview.index}>
            <Link
              data-gtm-category={GACategories.sidebarMenu}
              data-gtm-action="Overview"
              to={Routes.app.overview.index}
            >
              {t<string>(Content.navigation.main.overview)}
            </Link>
          </Menu.Item>
          {isAdmin || dataIntegrated ? (
            <>
              <Menu.Item icon={<BulbOutlined />} key={Routes.app.insights.index}>
                <Link
                  data-gtm-category={GACategories.sidebarMenu}
                  data-gtm-action="Insights"
                  to={Routes.app.insights.index}
                >
                  {t<string>(Content.navigation.main.insights)}
                </Link>
              </Menu.Item>
              <Menu.Item icon={<LineChartOutlined />} key={analytics?.url || "analytics"}>
                <Link
                  to={Routes.app.analytics.index}
                  data-gtm-category={GACategories.sidebarMenu}
                  data-gtm-action="Analytics"
                >
                  {t<string>(Content.navigation.main.analytics)}
                </Link>
              </Menu.Item>
              <Menu.Item icon={<ClockCircleOutlined />} key={timeline?.url || "timeline"}>
                <Link
                  to={Routes.app.timeline.index}
                  data-gtm-category={GACategories.sidebarMenu}
                  data-gtm-action="Farm timeline"
                >
                  {t<string>(Content.navigation.main.timeline)}
                </Link>
              </Menu.Item>
              {isAdmin ? (
                <Menu.Item icon={<ExperimentOutlined />} key={iofc?.url || "iofc"}>
                  <Link
                    to={Routes.app.iofc.index}
                    data-gtm-category={GACategories.sidebarMenu}
                    data-gtm-action="Simulations"
                  >
                    {t<string>(Content.navigation.main.iofc)}
                  </Link>
                </Menu.Item>
              ) : null}
              <Menu.Item icon={<Icon component={Copilot} />} key={farmSummaries?.url || "farm-summaries"}>
                <Link
                  to={Routes.app.farmSummaries.index}
                  data-gtm-category={GACategories.sidebarMenu}
                  data-gtm-action="Copilot"
                >
                  {t<string>(Content.navigation.main.farmSummaries)}
                </Link>
              </Menu.Item>
            </>
          ) : null}
          <Menu.Item
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="Collapse"
            className={styles.Collapse}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => onToggle(!collapsed)}
          >
            {t<string>(Content.navigation.main.collapse, {
              status: collapsed ? "collapsed" : "expanded"
            })}
          </Menu.Item>
        </Menu>

        <Menu
          mode="inline"
          selectedKeys={[pathname]}
          className={classname(styles.Wrapper)}
          selectable={false}
          triggerSubMenuAction="click"
          style={{ marginTop: "auto" }}
          inlineIndent={14}
        >
          <Menu.Divider />
          <Menu.Item icon={<UserOutlined />} key={myNetwork?.url || "myNetwork"}>
            <Link
              to={Routes.app.network.index}
              data-gtm-category={GACategories.sidebarMenu}
              data-gtm-action="My Connections"
            >
              {t<string>(Content.navigation.main.myConnections)}
            </Link>
          </Menu.Item>
          {/** settings?.url could return undefined which gives a warning in the console */}
          {isAdmin || dataIntegrated ? (
            <Menu.Item icon={<SettingOutlined />} key={settings?.url || "settings"}>
              <Link
                to={Routes.app.settings.index}
                data-gtm-category={GACategories.sidebarMenu}
                data-gtm-action="Settings"
              >
                {t<string>(Content.navigation.main.settings)}
              </Link>
            </Menu.Item>
          ) : null}
          {t<string>(Content.navigation.main.helpCenter) && (
            <Menu.Item icon={<InfoCircleOutlined />} key={Routes.app.helpCenter.index}>
              <a
                href="https://intercom.help/connecterra"
                rel="noopener noreferrer"
                data-gtm-category={GACategories.sidebarMenu}
                data-gtm-action="Help Center"
                onClick={() => {
                  window.open("https://intercom.help/connecterra", "_blank");
                }}
                target={"_blank"}
              >
                {t<string>(Content.navigation.main.helpCenter)}
              </a>
            </Menu.Item>
          )}
          <Menu.Item
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="Intercom"
            className={classname(intercomLauncherClassName, isChatOpen ? styles.Chat : styles.ChatUnselected)}
            icon={
              <Badge count={unreadCount}>
                <MessageOutlined />
              </Badge>
            }
            key={"chat"}
          >
            {t<string>(Content.navigation.main.support)}
          </Menu.Item>
          <Menu.Item
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="Logout"
            onClick={() => {
              dispatch(Session.actions.logout());
            }}
            icon={<LogoutOutlined />}
          >
            {t<string>(Content.navigation.main.logout)}
          </Menu.Item>
        </Menu>
      </>
    );
  }
);
