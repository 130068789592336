import { createModule, from, makeAsset } from "../../utils";

export const analytics = createModule("analytics", {
  /**
   * charts comparison module
   */
  compare: {
    ...from("title", "subtitle", "openEventList", "showAnnotations", "goback", "showTimeline"),
    dropdowns: {
      label: from("farm"),
      notFound: "notFound",
      placeholder: from("farm", "fetchingFarmList", "category", "chart"),
      suggestions: "suggestions"
    },
    empty: from("title", "description"),
    sections: makeAsset
  },
  correlations: {
    ...from(
      "alert",
      "pageTitle",
      "pageDescription",
      "suggestions",
      "events",
      "add",
      "goBack",
      "show",
      "selectedChart"
    ),
    headers: from("trend", "correlation", "chartSelect"),
    trend: "trend",
    correlation: "correlation",
    tooltip: "tooltip",
    metric: makeAsset,
    chart: {
      ...from("correlationHeader"),
      error: from("alreadyUsed", "noCorrelations")
    }
  },
  config: {
    ...from("pageTitle"),
    search: from("pageDescription", "title", "placeholder", "cta"),
    metric: { displayName: makeAsset, ...from("info") },
    placeholders: from("metric", "variant", "source"),
    variant: makeAsset,
    source: makeAsset,
    infoTip: "infoTip",
    projections: "projections"
  },
  v3: {
    ...from(
      "pageTitle",
      "pageDescription",
      "dashboard",
      "addCta",
      "addDashboard",
      "analysis",
      "browseCta",
      "editDescription"
    ),
    metricSelector: { view: "view" },
    empty: {
      dashboards: from("title", "description", "cta"),
      charts: from("title", "description", "cta")
    },
    confirm: {
      newTab: from("title", "description", "inputPlaceholder"),
      removeTab: from("title", "description"),
      renameTab: from("title", "description"),
      copyTab: from("title", "dashboard", "farm", "alert"),
      saveChart: from("title", "description"),
      removeChart: from("title", "description"),
      error: from("duplicateTab")
    },
    config: {
      cta: from("save", "add", "update", "back", "cancel")
    },
    tooltips: from("dashboard", "addChart")
  },
  feedback: from(
    "title",
    "description",
    "date",
    "feedback",
    "submit",
    "cancel",
    "done",
    "thankYou",
    "nextSteps"
  )
});
