import * as _ from "lodash";

import { FarmEntity, Insights, InsightType } from "@ctra/api";
import { MONTH, Optional } from "@ctra/utils";

import { useFarm, useFarmList } from "@farms";

type LatestInsights = {
  insightListQuery: Optional<Record<string, unknown>>;
  insightListName: string;
};

/**
 * List query and list name for latest insights
 */
export const useOnboardingInsights = (farmID?: FarmEntity["id"]): LatestInsights => {
  const { farm } = useFarm();
  const { farmList, isLoading: isFarmListLoading } = useFarmList();

  /**
   * Make a query for the insight list widget
   * @todo don't add farm info here, that goes one level deeper
   */
  const insightListQuery = isFarmListLoading
    ? void 0
    : {
        orderBy: "endEpoch desc",
        expand: Insights.presets.expandDefaults(),
        filter: {
          ...Insights.queries.ofType(InsightType.onboarding),
          farmID: farm?.id || { in: _.map(_.map(farmList, "id"), _.toNumber) },
          insightState: { in: Insights.presets.insightStates() }
        }
      };

  /**
   * Set an insight list name, append the farm name or id if available.
   */
  const farmId = farm ? _.kebabCase(farm.name as string) || farm.id : null;
  const insightListName = `onboardingInsights${farmId ? `-${farmId}` : ""}`;

  return { insightListQuery, insightListName };
};
