import { useState } from "react";
import * as _ from "lodash";
import { Datum, LineConfig, MixConfig } from "@ant-design/charts";
import { useDeepCompareMemo, useDeepCompareEffect } from "use-deep-compare";
import moment from "moment";

import { Line, LineData } from "@ctra/api";
import { hslToHex, TS, getTimeOfDay, Epoch } from "@ctra/utils";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";

import { useChartContext, useSeriesColor, useAnnotations } from "../../../providers";
import { useCommonConfig, useMinMax } from "../../../hooks";
import { LibraryConfigType } from "../../../typings";
import { filterLineBySeries } from "../../../utils";
import "./Tooltip.css";
import "./geometries";
import "./interactions";

const {
  iofc: {
    chart: { today: todayLabel }
  }
} = Content;

/**
 * Map predictions into the line data
 * @param {Line} data
 * @return {Line}
 */
const mapAnomalies = (data: Line): Line =>
  _.map(data, ({ y, anomaly, ...rest }) => ({
    y, // y: _.get(anomaly, ["prediction"], y),
    anomaly,
    ...rest
  }));

/**
 * Hook that merges line config with the common config
 * The common config coming from the main app overrides the preset config
 * @return {{config: MixConfig}}
 */
export const useLineConfig = (): {
  config: MixConfig | LineConfig;
} => {
  const { getColor } = useSeriesColor();
  const { commonConfig } = useCommonConfig<LineConfig>();
  const { t } = useTranslation();
  const { eventGroups } = useAnnotations();

  const {
    entity,
    data,
    config: contextConfig,
    series,
    meta,
    dataOptions: { timePeriod },
    viewOptions: { showEvents, showAnomalies }
  } = useChartContext<LineData>();

  const [filteredData, setFilteredData] = useState<typeof data>(data);
  const { min: minimum, max: maximum } = useMinMax(filteredData, "y");

  const today = moment().format("YYYY-MM-DD");
  const { seriesType, axis, series: metaSeries } = _.defaultTo(meta, {});

  /**
   * Show projections if the end date is after today
   */
  const showProjections = moment(timePeriod?.endDate).isAfter(moment(today).add(1, "days"));
  /**
   * Get the series keys from the metadata
   */
  const seriesKeys = _.get(meta, ["series", "keys"], {});

  /**
   * Count the number of items in the legend
   * @type {number}
   */
  const legendSize = _.size(series) || _.size(seriesKeys);

  useDeepCompareEffect(() => {
    setFilteredData(filterLineBySeries(data, meta, series));
  }, [data, meta, series]);

  /**
   * Line chart config
   */
  const lineConfig: LineConfig = useDeepCompareMemo(
    () =>
      _.merge({}, commonConfig, {
        /**
         * Custom info for the shapes
         */
        customInfo: "Test: this should be accessible in registerShape in some way.",
        /**
         * turn off the legends if there is only one series
         */
        legend:
          legendSize > 1
            ? _.isUndefined(contextConfig.legend)
              ? commonConfig.legend
              : contextConfig.legend
            : false,
        /**
         * Don't connect null values
         */
        connectNulls: false,
        /**
         * Line data
         */
        data: showAnomalies ? filteredData : mapAnomalies(filteredData),
        /**
         * Pull the annotations from the context config
         */
        annotations:
          showProjections && entity.dataProperties.maxProjectionInterval
            ? _.compact(
                _.concat(contextConfig.annotations, [
                  {
                    type: "region",
                    start: [today, "min"],
                    end: ["max", "max"],
                    style: {
                      fill: "#CCF9FF",
                      fillOpacity: 1
                    }
                  },
                  {
                    type: "line",
                    start: [today, 0],
                    end: [today, "max"],
                    text: {
                      content: t<string>(todayLabel),
                      position: "right",
                      offsetX: 18,
                      offsetY: 5,
                      style: {
                        textAlign: "right"
                      }
                    },
                    style: {
                      lineWidth: 2,
                      lineDash: [4, 4]
                    }
                  }
                ])
              )
            : contextConfig.annotations,
        /**
         * Tooltip settings
         */
        tooltip: _.isUndefined(contextConfig.tooltip)
          ? {
              showCrosshairs: true,
              containerTpl: `
            <div class="g2-tooltip g2-lineChart-tooltip">
              <ul class="g2-tooltip-list"></ul>
              <div class="g2-tooltip-title"></div>
            </div>
          `,
              crosshairs: {
                type: "x",
                line: {
                  style: {
                    lineDash: [4, 4]
                  }
                }
              },
              marker: {
                zIndex: 0
              },
              title: (value: string, { x }: Line[number]) => TS.asMoment(x).format("LL"),
              formatter: (datum: Datum): { name: string; value: string | number } => {
                const axisUnit = _.get(meta, ["yAxis", "unit"]);
                const isTimeOfDay = axisUnit === "timeOfDay" && _.isNumber(datum.y);

                const value = isTimeOfDay
                  ? getTimeOfDay(Number(datum.y))
                  : _.isNumber(datum.y)
                  ? _.round(datum.y, 2)
                  : _.isNull(datum.y)
                  ? "N/A"
                  : datum.y;

                return {
                  name: _.get(metaSeries, ["keys", datum.seriesField], datum.seriesField),
                  value
                };
              }
            }
          : contextConfig.tooltip,
        /**
         * Field settings
         */
        xField: "x",
        yField: "y",
        seriesField: "seriesField",
        /**
         * Custom shapes
         */
        lineShape: showAnomalies ? "anomaly-line" : "hidden-anomaly-line",
        point: showAnomalies
          ? {
              shape: "anomaly-point"
            }
          : void 0,
        /**
         * X axis styling
         */
        xAxis: {
          tickMethod: (time: any) => {
            return time.values;
          },
          label: {
            offset: 10
          },
          line: {
            style: {
              lineWidth: 0.5,
              stroke: "#000000"
            }
          },
          nice: true,
          tickLine: {
            length: 3,
            style: {
              lineWidth: 1,
              stroke: "#000000"
            }
          },
          /**
           * @todo keep an eye on this
           * range is changed to [0.5, 0.5] if there is only one date on the x-axis
           * this is used to center the single data points on the center of the x-axis
           */
          range: new Set(data.map((item) => item?.x)).size === 1 ? [0.5, 0.5] : [0, 1]
        },
        meta: {
          x: {
            sync: true,
            type: "time",
            formatter: (value: number) => {
              const moment = Epoch.asMoment(Epoch.load(value));
              const format = meta.xType === "weekDay" ? "DD MMM" : meta.xType === "month" ? "MMM YYYY" : "L";

              return moment.utc().format(format);
            }
          }
        },
        /**
         * y-axis styling
         */
        yAxis: {
          label: {
            formatter: (value: string) => {
              const axisUnit = _.get(meta, ["yAxis", "unit"]);
              const isTimeOfDay = axisUnit === "timeOfDay" && _.isNumber(Number(value));

              return isTimeOfDay ? getTimeOfDay(Number(value)) : _.round(parseFloat(value), 2);
            }
          },
          // apply a bit loser factor to the min and max values
          max: maximum * 1.3,
          min: minimum * 0.7,
          title: {
            text: _.get(axis, ["y", "title"], "")
          }
        },
        /**
         * Get colors for the lines
         * @param {any} seriesField
         * @return {any}
         */
        color: ({ seriesField }: Datum) => hslToHex(getColor(seriesType as string, seriesField))
      }),
    [
      filteredData,
      axis,
      commonConfig,
      maximum,
      minimum,
      metaSeries,
      seriesType,
      showAnomalies,
      contextConfig.annotations,
      getColor
    ]
  );

  /**
   * Farm events line config
   */
  const farmEventLineConfig: LineConfig = useDeepCompareMemo(
    () => ({
      animation: false,
      /**
       * Add the farm event info to the points where they exist
       */
      data: _.map(_.uniqBy(filteredData, "x"), ({ x }) => {
        const visibleEvents = _.isPlainObject(showEvents) ? _.keys(_.omitBy(showEvents, (on) => !on)) : [];
        const eventsOfX = _.get(eventGroups, [x], []);
        const filteredEvents = _.filter(eventsOfX, ({ source: { type } }) => visibleEvents.includes(type));

        return {
          x,
          /**
           * This trick allows to easily determine the height of the graph in pixels.
           * The value 0 will become "bottom of graph".
           */
          y: 0,
          seriesField: "farm-event",
          /**
           * Append the events to the data
           */
          events: filteredEvents
        };
      }),
      /**
       * Tooltip settings
       */
      tooltip: false,
      /**
       * Field settings
       */
      xField: "x",
      yField: "y",
      seriesField: "seriesField",
      /**
       * Turn off axes
       */
      xAxis: false,
      yAxis: false,
      /**
       * Point for farm events
       */
      point: {
        shape: "farm-event-point",
        state: {
          active: {
            style: () => ({
              stroke: "#007180"
            })
          },
          /**
           * Keep this empty so the interaction can be responsible for the
           * styling as this point has more components to target separately
           */
          selected: {
            style: () => ({
              stroke: "#007180",
              lineDash: [0, 0]
            })
          }
        }
      },
      /**
       * Line styling
       */
      lineStyle: {
        opacity: 0
      },
      /**
       * Hook up interactions
       */
      interactions: [
        { type: "hover-cursor" },
        { type: "element-active" },
        { type: "element-single-selected" }
      ]
    }),
    [filteredData, eventGroups, showEvents]
  );

  /**
   * Mix chart config
   */
  const mixConfig: MixConfig | LibraryConfigType = useDeepCompareMemo(
    () =>
      showEvents
        ? {
            syncViewPadding: true,
            plots: _.compact([
              {
                type: "line",
                options: lineConfig
              },
              {
                type: "line",
                options: farmEventLineConfig
              }
            ])
          }
        : lineConfig,
    [farmEventLineConfig, lineConfig, showEvents]
  );

  return useDeepCompareMemo(
    () => ({
      config: _.merge(
        // no tooltip for the chart as a whole
        {
          tooltip: false,
          ..._.omit(contextConfig, "annotations")
        },
        // use the common config without tooltip
        _.omit(commonConfig, "tooltip"),
        // add in the mix config which has tooltip definitions
        mixConfig
      )
    }),
    [contextConfig, commonConfig, mixConfig]
  );
};
